import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, tap } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { TreasuryStateService } from '@dougs/synchronized-accounts/shared';
import { VendorInvoiceStatsStateService } from '@dougs/vendor-invoice/shared';

@Injectable()
export class VendorInvoiceStatsComponentService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  constructor(
    private readonly vendorInvoiceStatsStateService: VendorInvoiceStatsStateService,
    private readonly treasuryStateService: TreasuryStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
  ) {}

  refreshVendorInvoiceStats$: Observable<[void, void]> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.next(true)),
    concatMap((company) => this.refreshStats(company)),
    tap(() => this.isLoading.next(false)),
  );

  async refreshStats(activeCompany?: Company): Promise<[void, void | undefined]> {
    const companyId: number = activeCompany ? activeCompany.id : this.companyStateService.activeCompany.id;
    return Promise.all([
      this.vendorInvoiceStatsStateService.refreshVendorInvoiceStats(companyId),
      this.companyServicesStateService.services?.accounting.shouldShowModuleLink
        ? this.treasuryStateService.refreshAccountBalances(companyId)
        : Promise.resolve(undefined),
    ]);
  }
}
