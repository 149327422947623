import { VendorInvoice, VendorInvoicePaymentStatus } from '@dougs/vendor-invoice/dto';

export function getVendorInvoicePaymentStatus(vendorInvoice: VendorInvoice): string {
  switch (vendorInvoice.paymentStatus) {
    case VendorInvoicePaymentStatus.PAID:
      return 'Payée';
    case VendorInvoicePaymentStatus.NOT_PAID:
      return 'Non payée';
    case VendorInvoicePaymentStatus.PARTIALLY_PAID:
      return 'Partiellement payée';
  }
}
