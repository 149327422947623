import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { Operation } from '@dougs/operations/dto';
import {
  OperationService,
  SourceDocumentAttachmentUtils,
  VendorInvoiceOperationsStateService,
} from '@dougs/operations/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { getVendorInvoicePaymentStatus } from '../../utils/vendor-invoice.utils';

@Injectable()
export class VendorInvoiceOperationComponentService {
  private readonly isOperationAttached: WritableSignal<boolean> = signal(false);
  public isOperationAttached$: Signal<boolean> = computed(() => this.isOperationAttached());

  isLoading = false;
  operation!: Operation;
  isEcommerceOperation = false;

  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    private readonly operationService: OperationService,
    private readonly metricsService: MetricsService,
  ) {}

  setOperation(operation: Operation): void {
    this.operation = operation;
    this.isEcommerceOperation = this.operationService.isEcommerceOperation(this.operation);
  }

  addVendorInvoiceToOperation(vendorInvoice: VendorInvoice, addToVendorInvoiceOperationState: boolean): void {
    const salesInvoiceSourceDocumentAttachment: SourceDocumentAttachment =
      SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromVendorInvoice(vendorInvoice);
    this.operation = {
      ...this.operation,
      sourceDocumentAttachments: [
        ...(this.operation?.sourceDocumentAttachments?.filter(
          (sda) => sda.sourceDocument?.externalId !== vendorInvoice.id,
        ) ?? []),
        salesInvoiceSourceDocumentAttachment,
      ],
    };
    if (addToVendorInvoiceOperationState) {
      this.vendorInvoiceOperationsStateService.addOperationState(this.operation);
    }
  }

  removeVendorInvoiceFromOperation(vendorInvoiceId: string, removeFromVendorInvoiceOperationState: boolean): void {
    this.operation = {
      ...this.operation,
      sourceDocumentAttachments: [
        ...(this.operation?.sourceDocumentAttachments?.filter(
          (sda) => sda?.sourceDocument?.externalId !== vendorInvoiceId,
        ) ?? []),
      ],
    };
    if (removeFromVendorInvoiceOperationState) {
      this.vendorInvoiceOperationsStateService.removeOperationState(this.operation);
    }
  }

  async attachOperation(
    e: Event,
    operation: Operation,
    vendorInvoice: VendorInvoice,
    isInModal = false,
  ): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    if (!this.isLoading) {
      this.isLoading = true;
      this.addVendorInvoiceToOperation(vendorInvoice, !isInModal);
      this.isOperationAttached.set(true);
      this.isOperationAttached.set(await this.vendorInvoiceStateService.attachOperation(operation, vendorInvoice));
      if (this.isOperationAttached()) {
        isInModal
          ? this.metricsService.pushMixpanelEvent('Operation Supplier Invoice Attached', {
              Status: getVendorInvoicePaymentStatus(vendorInvoice),
            })
          : this.metricsService.pushMixpanelEvent('Supplier Invoice Operation Added');
      } else {
        this.removeVendorInvoiceFromOperation(vendorInvoice.id, !isInModal);
      }
      this.isLoading = false;
    }
  }

  async detachOperation(
    e: Event,
    operation: Operation,
    vendorInvoice: VendorInvoice,
    isInModal = false,
  ): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    if (!this.isLoading) {
      this.isLoading = true;
      this.removeVendorInvoiceFromOperation(vendorInvoice.id, !isInModal);
      this.isOperationAttached.set(false);
      this.isOperationAttached.set(!(await this.vendorInvoiceStateService.detachOperation(operation, vendorInvoice)));
      if (!this.isOperationAttached()) {
        isInModal
          ? this.metricsService.pushMixpanelEvent('Operation Supplier Invoice Detached', {
              Status: getVendorInvoicePaymentStatus(vendorInvoice),
            })
          : this.metricsService.pushMixpanelEvent('Supplier Invoice Operation Deleted');
      } else {
        this.addVendorInvoiceToOperation(vendorInvoice, !isInModal);
      }
      this.isLoading = false;
    }
  }

  attachTempOperation(): void {
    this.isOperationAttached.set(true);
  }

  detachTempOperation(): void {
    this.isOperationAttached.set(false);
  }

  operationIsAttached(operation: Operation | null, vendorInvoice: VendorInvoice): void {
    if (operation && vendorInvoice) {
      this.isOperationAttached.set(
        vendorInvoice.operations.some((operationIterated) => operationIterated.id === operation.id),
      );
    }
  }
}
