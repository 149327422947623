<div dougsModalTitle>
  <h6>Renommer la facture</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<form
  (ngSubmit)="renameVendorInvoiceModalComponentService.renameVendorInvoice()"
  [formGroup]="renameVendorInvoiceModalComponentService.labelFormGroup"
>
  <div dougsModalContent class="modal-select-association-container">
    <dougs-form-field>
      <input
        type="text"
        formControlName="label"
        dougsFormFieldControl
        placeholder="Nom de votre facture"
        dougsAutofocus
      />
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button [dougsModalClose] color="secondary">Annuler</dougs-button>
    <dougs-button [disabled]="!renameVendorInvoiceModalComponentService.labelFormControl?.value" type="submit">
      Renommer
    </dougs-button>
  </div>
</form>
