import { Injectable, Optional } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { RenameVendorInvoiceModalComponent } from '../../modals/rename-vendor-invoice-modal/rename-vendor-invoice-modal.component';
import { VendorInvoiceStatsComponentService } from '../list/vendor-invoice-stats.component.service';

@Injectable()
export class VendorInvoiceActionsComponentService {
  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    @Optional() private readonly vendorInvoiceStatsComponentService: VendorInvoiceStatsComponentService,
  ) {}

  async deleteVendorInvoice(vendorInvoice: VendorInvoice): Promise<boolean> {
    if (await this.confirmDeletion()) {
      const hasBeenDeleted: boolean = await this.vendorInvoiceStateService.deleteVendorInvoice(vendorInvoice);
      if (hasBeenDeleted) {
        await this.vendorInvoiceStatsComponentService?.refreshStats();
        this.metricsService.pushMixpanelEvent('Supplier Invoice Deleted');
      }
      return hasBeenDeleted;
    }
    return false;
  }

  renameVendorInvoice(vendorInvoice: VendorInvoice): void {
    this.modalService.open<void>(RenameVendorInvoiceModalComponent, {
      data: vendorInvoice,
    });
  }

  private async confirmDeletion(): Promise<boolean> {
    return !!(
      await lastValueFrom(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Supprimer la facture',
            body: 'Votre facture et toutes les modifications apportées seront perdues.',
            yesText: 'Supprimer la facture',
            noText: 'Annuler',
          },
        }).afterClosed$,
      )
    ).data;
  }
}
