import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AutofocusDirective,
  BlurOnEnterDirective,
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { RenameVendorInvoiceModalComponentService } from '../../services/modals/rename-vendor-invoice-modal.component.service';

@Component({
  selector: 'dougs-rename-vendor-invoice-modal',
  templateUrl: './rename-vendor-invoice-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalFooterDirective,
    ButtonComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    ControlFormFieldDirective,
    AutofocusDirective,
    BlurOnEnterDirective,
  ],
  providers: [RenameVendorInvoiceModalComponentService],
})
export class RenameVendorInvoiceModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public readonly vendorInvoice: VendorInvoice,
    public readonly renameVendorInvoiceModalComponentService: RenameVendorInvoiceModalComponentService,
  ) {}

  ngOnInit(): void {
    this.renameVendorInvoiceModalComponentService.setVendorInvoice(this.vendorInvoice);
  }
}
