import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { mergeObjects } from '@dougs/core/utils';
import { ModalRef } from '@dougs/ds';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';

@Injectable()
export class RenameVendorInvoiceModalComponentService {
  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly modalRef: ModalRef,
  ) {}

  get labelFormControl(): FormControl<string> {
    return this.labelFormGroup?.get('label') as FormControl;
  }

  vendorInvoice!: VendorInvoice;
  labelFormGroup: FormGroup = new FormGroup<{ label: FormControl<string> }>({
    label: new FormControl<string>('', { nonNullable: true }),
  });

  setVendorInvoice(vendorInvoice: VendorInvoice): void {
    this.vendorInvoice = vendorInvoice;
    this.setLabel(vendorInvoice.label);
  }

  get label(): string {
    return this.labelFormControl.value ?? '';
  }

  setLabel(label: string): void {
    this.labelFormControl.setValue(label ?? '');
  }

  async renameVendorInvoice(): Promise<void> {
    if (this.label !== this.vendorInvoice.label) {
      await this.vendorInvoiceStateService.updateVendorInvoice(mergeObjects(this.vendorInvoice, { label: this.label }));
    }
    this.modalRef.close();
  }
}
